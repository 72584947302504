import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import { isRunningInIframe } from "../../utils/httpUtilities";
import { CONFIG } from "../../config-global";
import { langPrefix } from "../../locales";
import "./footer.scss";

let cachedLogo: any | undefined;

/*
 * Footer component to displays the logo
 */
const Footer = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix: langPrefix.layout.footer.key });
  const [logoData, setLogoData] = useState<any>(null);

  const isDraycir = CONFIG.site.productOemId === "draycir";

  useEffect(() => {
    if (cachedLogo !== undefined) {
      setLogoData(cachedLogo);
    } else {
      // Load the logo and store it in cache
      const loadLogoFunc = async () => {
        try {
          const logo = await import(`../../assets/svg/paythem-footer-${CONFIG.site.productOemId}.svg`);
          cachedLogo = logo.default;
          setLogoData(logo.default);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error loading the logo:", error);
        }
      };
      loadLogoFunc();
    }
  }, []);

  // No footer in iframe
  if (isRunningInIframe()) return <></>;

  return (
    <div className='dc-footer gap-3'>
      {isDraycir && <div>{t("poweredBy")}</div>}
      <a href={CONFIG.site.productSite}>
        <img src={logoData} className='footer-logo' alt='PayThem Logo' />
      </a>
      {isDraycir && <div>{t("aDraycirProduct")}</div>}
    </div>
  );
};

export default Footer;
