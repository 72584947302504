// eslint-disable-next-line import/prefer-default-export
export const CONFIG = {
  site: {
    name: process.env.REACT_APP_PRODUCT_NAME || "",
    shortName: process.env.REACT_APP_PRODUCT_SHORTNAME || "",
    productOemId: process.env.REACT_APP_PRODUCT_OEM_ID || "",
    productSite: process.env.REACT_APP_PRODUCTSITE_URL || "",
    payerSiteUrl: process.env.REACT_APP_PAYTHEM_PAYERSITE_URL || "",
    adminSiteUrl: process.env.REACT_APP_PATHEM_ADMINSITE_URL || "",
  },
};
