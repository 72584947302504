// eslint-disable-next-line import/prefer-default-export
export const langPrefix = {
  layout: {
    footer: {
      key: "footer",
    },
  },

  payments: {
    paymentForm: {
      key: "paymentForm",
    },
  },

  paymentProvider: {
    key: "paymentProvider",
    directDebit: {
      key: "paymentProvider.directDebit",
    },
  },

  paymentProviders: {
    key: "paymentProviders",
    gocardless: {
      key: "paymentProviders.gocardless",
    },
    opayo: {
      key: "paymentProviders.opayo",
    },
    stripe: {
      key: "paymentProviders.stripe",
    },
    totalProcessing: {
      key: "paymentProviders.totalProcessing",
    },
    transafe: {
      key: "paymentProviders.tranSafe",
    },
    trueLayer: {
      key: "paymentProviders.trueLayer",
    },
  },

  paymentMethodSelection: {
    key: "paymentMethodSelection",
  },

  dialogs: {
    dialogRemovePaymentMethod: {
      key: "dialogs.dialogRemovePaymentMethod",
    },
  },
};
